import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/auth'
const config = {
    apiKey: "AIzaSyD5e_bAQeAl1LjE97iBXgbuqGTeQaVo3Ng",
    authDomain: "silentknights-cc433.firebaseapp.com",
    projectId: "silentknights-cc433",
    storageBucket: "silentknights-cc433.appspot.com",
    messagingSenderId: "737528268871",
    appId: "1:737528268871:web:649142180f7d3e83494962",
    measurementId: "G-LEN39PWEST"
};
firebase.initializeApp(config);
const db = firebase.firestore();
const auth = firebase.auth();

auth.languageCode = 'nl';

const notesRef = db.collection('notes');
const usersRef = db.collection('users');
const conversationsRef = db.collection('conversationsNew');
//const checkinRef = db.collection('checkins');
export {notesRef,auth, usersRef, db, conversationsRef};
export default db;