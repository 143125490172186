<template>
  <div class="">
    <v-container class="grey lighten-5 pa-5">
          
          <div v-if="!user.loggedIn" class=""> 
             <h1 class="font-weight-light mb-3 pt-6">
              Silent Knights
             </h1>
             <h3 class="font-weight-light pb-12">Offer help to those in need, anonymous, when you want to</h3> 
          <v-row>
            <v-col>
              <h2>What is this?</h2>
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Delectus, quasi numquam qui molestias repellat veritatis ipsa quo porro! Mollitia velit dicta fugiat ad officia rerum deleniti veniam numquam eaque ea.</p>
            </v-col>
            <v-col>
              <h2>For who?</h2>
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Delectus, quasi numquam qui molestias repellat veritatis ipsa quo porro! Mollitia velit dicta fugiat ad officia rerum deleniti veniam numquam eaque ea.</p>
            </v-col></v-row>
           
          </div>
          <div v-if="user.loggedIn">
              <small>{{user.data.name}}, welcome back!</small>
              <h2 class="font-weight-light">All questions</h2>
              <v-list>
                <v-list-item-group active-class="green--text">
                  <template v-for="(item, index) in allNotes">
                    <v-list-item :key="item.id">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title class="text--primary">
                            <router-link
                              style="text-decoration: none; color: #333"

                              :to="{ name: 'Note', params: { id: item.id } }"
                            >
                            {{item.title}}
                           
                            </router-link>
                            
                          </v-list-item-title>
                          
                        </v-list-item-content>
                          
                        <v-list-item-action>
                            <v-btn
                              color="secondary"
                              outlined
                              fab
                              x-small
                              dark
                              @click="deleteDate(item)"
                            >
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
              
                          <!-- <v-list-item-action-text >Verwijderen</v-list-item-action-text> -->
                        </v-list-item-action>
                      </template>
                    </v-list-item>

                    <v-divider
                      v-if="index < yourNotes.length - 1"
                      :key="index"
                    ></v-divider>
                  </template>
                </v-list-item-group>
              </v-list>
          </div> 
        
    </v-container>
  </div>
</template>
<style>
li {
  line-height: 1.7em;
}
</style>
<script>
import { mapGetters } from "vuex";
import Vue from "vue";
import {  notesRef } from "../store/firestore";
//import store from "../store/";
import * as moment from "moment/moment";
export default {
  name: "Home",
  components: {

  },
  data: () => ({
    allNotes:[],
    yourNotes:[]
  }),
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
    }),
  },
  created() {
    this.getData();
  },
  filters: {
    datumLeesbaar: function (date) {
      return moment(date).format("dddd DD MMMM 'YY");
    },
  },
  methods: {
   
    getData() {
      var that = this;
      that.allNotes = [];
      // haal alle events op in de toekomst
      notesRef
        //.where("datum", ">=", moment().format("YYYY-MM-DD"))
        .get()
        .then(function (querySnapshot) {
          
            querySnapshot.forEach(function (doc) {
              // doc.data() is never undefined for query doc snapshots
              var test = doc.data();
              test.id = doc.id;
              that.allNotes.push(test);
            });
          if (that.user.loggedIn) {
          // that.getMyDates();
          }
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
    },
    saveMyDates() {
      var that = this;
      this.yourNewDates.forEach(function (date) {
        notesRef
          .add({
            naam: that.user.data.name,
            datum: date,
            uid: that.user.data.uid,
            email: that.user.data.email,
          })
          .then(function () {
            // clear selection
            that.yourNewDates = [];
            that.getData();
          })
          .catch(function (error) {
            console.error("Error adding document: ", error);
          });
      });
    },
    getMyDates() {
      var that = this;
      // haal alle events op in de toekomst
      notesRef
        .where("uid", "==", that.user.data.uid)
        //.where("datum", ">=", moment().format("YYYY-MM-DD"))
        .onSnapshot((querySnapshot) => {
        //  (that.yourNotes.splice(0)),
            querySnapshot.forEach(function (doc) {
              // doc.data() is never undefined for query doc snapshots
              var temp = doc.data();
              temp.id = doc.id;
              //that.yourDates.push(temp);
              Vue.set(that.yourNotes, that.yourNotes.length, temp)
            });
          // that.yourDates.sort(function (a, b) {
          //   a = a.datum.split("-").join("");
          //   b = b.datum.split("-").join("");
          //   return a > b ? 1 : a < b ? -1 : 0;
          // });
          
        })
    },
    deleteDate(walkItem) {
      if (confirm("Wil je deze echt verwijderen?")) {
        var that = this;
        var item = walkItem;
        notesRef
          .doc(walkItem.id)
          .delete()
          .then(function () {
            
            // find for partners !  TO DO
            if (item.partnerFound) {
              // remove partner infor en partnerFound, van
              // notesRef () met id item.partner.id
              notesRef
                .doc(item.partner.id)
                .set(
                  {
                    partnerFound: false,
                    partner: {},
                  },
                  { merge: true }
                )
                .then(function () {
              
                  console.log("Document successfully updated!");
                })
                .catch(function (error) {
                  console.error("Error writing document: ", error);
                });
            }
            // en vernieuwen.
            that.getData();
          })
          .catch(function (error) {
            console.error("Error removing document: ", error);
          });
      }
    },
  },
};
</script>