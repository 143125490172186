<template>
  <v-app-bar app color="accent">
    <v-btn to="/" text>
      <v-icon>mdi-home-outline</v-icon>
    </v-btn>
    <v-btn to="/admin" v-if="user.isAdmin" text>
      <span class="mr-2">admin</span>
    </v-btn>
    <v-spacer></v-spacer>
    <v-btn v-if="user.loggedIn" to="/newnote/" text>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
    <v-menu offset-y v-if="user.loggedIn && user.data">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" text>
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item>
          <v-btn v-if="user.loggedIn && user.data" to="/profile" text>
            <v-icon>mdi-account-edit</v-icon> &nbsp;
            <span class="pl-2">Your profile</span>
          </v-btn>
        </v-list-item>
       
         <v-list-item>
           <v-btn v-if="user.loggedIn && user.data" @click.native="signOut" text>
               <v-icon>mdi-logout-variant</v-icon> &nbsp;
             <span class="pl-2">Sign out</span>
          </v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
   
    <span v-else>
      <v-btn to="/register" text>
        <span class="mr-2">Register</span>
      </v-btn>
      <v-btn to="/login" text>
        <span class="mr-2">Sign in</span>
        <v-icon>mdi-login</v-icon>
      </v-btn>
    </span>
  </v-app-bar>
</template>
<script>
import { mapGetters } from "vuex";
import store from "../store/index";
import firebase from "firebase/app";
import "firebase/auth"
export default {
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
    }),
  },
  methods: {
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          store.dispatch("fetchUser", null);
          this.$router.replace({
            name: "Home",
          });
        });
    },
  },
};
</script>